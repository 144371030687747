import React from 'react'
import { Tab } from '@mui/material'
import Tabs from '@components/organisms/TabPanel'

const NewsTabs = () => (
  <Tabs
    centered={false}
    disableGutters={true}
    maxWidth="lg"
    variant="fullWidth"
    mobilVariant="scrollable"
  >
    <Tab label="最新" value="/" />
    <Tab label="工業" value="/factory" />
    <Tab label="建築" value="/architecture" />
    <Tab label="業界トピック" value="/topics" />
    <Tab label="トラブル集" value="/issues" />
    <Tab label="用語集" value="/words" />
  </Tabs>
)

export default NewsTabs
