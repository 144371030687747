import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { useSigninCheck } from 'reactfire'
import dynamic from 'next/dynamic'
import Router, { useRouter } from 'next/router'
import Link from 'next/link'
import {
  AppBar,
  Button,
  BottomNavigation,
  BottomNavigationAction,
  Drawer,
  Divider,
  Grid,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode'
import FolderIcon from '@mui/icons-material/Folder'
import MenuIcon from '@mui/icons-material/Menu'
import SearchIcon from '@mui/icons-material/Search'
import { AnimationSpring } from '@components/atoms/AnimationSpring'

const PREFIX = 'AppDrawer'

const classes = {
  appFrame: `${PREFIX}-appFrame`,
  drawerHeader: `${PREFIX}-drawerHeader`,
  toolbarContainer: `${PREFIX}-toolbarContainer`,
  content: `${PREFIX}-content`,
  caption: `${PREFIX}-caption`,
  icon: `${PREFIX}-icon`,
  link: `${PREFIX}-link`,
  logoContainer: `${PREFIX}-logoContainer`,
  logo: `${PREFIX}-logo`,
  navigation: `${PREFIX}-navigation`,
  toolbarRight: `${PREFIX}-toolbarRight`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.appFrame}`]: {
    display: 'flex',
    height: '100%',
    position: 'relative',
    width: '100%',
  },

  [`& .${classes.drawerHeader}`]: theme.mixins.toolbar,

  [`& .${classes.toolbarContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
  },

  [`& .${classes.content}`]: {
    backgroundColor: 'white',
    height: 'calc(100% - 56px)',
    marginTop: 56,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100% - 64px)',
      marginTop: 64,
      width: '100%',
    },
  },

  [`& .${classes.caption}`]: {
    fontSize: '12px',
    margin: theme.spacing(1),
    width: 230,
    wordWrap: 'break-word',
  },

  [`& .${classes.icon}`]: {
    fontSize: '13px',
  },

  [`& .${classes.link}`]: {
    color: 'black',
    textDecoration: 'none',
  },

  [`& .${classes.logoContainer}`]: {
    objectFit: 'cover',
    width: 140,
    [theme.breakpoints.up('md')]: {
      width: 180,
    },
  },

  [`& .${classes.logo}`]: {
    display: 'block',
    height: 'auto',
    maxWidth: '100%',

    '&:hover': {
      cursor: 'pointer',
    },
  },

  [`& .${classes.navigation}`]: {
    backgroundColor: 'white',
  },

  [`& .${classes.toolbarRight}`]: {
    marginLeft: 'auto',
  },
}))

const DynamicLogOut = dynamic(import('./LogOutButton'))
const DynamicSignIn = dynamic(import('./SignInButton'))

const PromotionSignUpForAnonymousUser = () => {
  const { status, data: signInCheckResult } = useSigninCheck()

  if (status === 'loading' || (status === 'error' && signInCheckResult.signedIn)) {
    return null
  }

  return (
    <Button
      color="primary"
      onClick={() => {
        Router.push('/signin')
      }}
    >
      <Typography>無料会員登録</Typography>
    </Button>
  )
}

const LoginUserMenu = () => {
  const { status, data: signInCheckResult } = useSigninCheck()

  if (status === 'loading' || (status === 'error' && signInCheckResult.signedIn)) {
    return null
  }

  return (
    <ListItem button={true}>
      <Link href={'/account'}>
        <ListItemText primary="アカウント" />
      </Link>
    </ListItem>
  )
}

const AppDrawer: React.FC<{ children: React.ReactNode }> = (props) => {
  const router = useRouter()
  const [isOpen, setIsOpen] = useState(false)

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    Router.push(newValue)
  }

  const handleDrawerOpen = () => {
    setIsOpen(true)
  }

  const handleDrawerClose = () => {
    setIsOpen(false)
  }

  return (
    <Root className={classes.appFrame}>
      <AppBar color="inherit" elevation={0} variant="outlined">
        <Toolbar disableGutters={!isOpen}>
          <Grid
            container={true}
            justifyContent="space-between"
            alignContent="center"
            alignItems="center"
          >
            <Grid item={true}>
              <Grid container={true} alignItems="center">
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  size="large"
                >
                  <MenuIcon />
                </IconButton>
                <div className={classes.logoContainer}>
                  <Link href="/">
                    <img alt="coataz" className={classes.logo} src="/img/logo.png" />
                  </Link>
                </div>
              </Grid>
            </Grid>
            <Grid item={true}>
              <Hidden only={['xs', 'sm']}>
                <Grid container={true}>
                  {router.pathname !== '/signin' && (
                    <AnimationSpring>
                      <React.Suspense fallback={<div />}>
                        <DynamicSignIn />
                      </React.Suspense>
                    </AnimationSpring>
                  )}
                  <BottomNavigation
                    className={classes.navigation}
                    onChange={handleChange}
                    showLabels={true}
                    value={router.pathname}
                  >
                    <BottomNavigationAction
                      icon={<ChromeReaderModeIcon />}
                      label="ニュース"
                      value="/"
                    />
                    <BottomNavigationAction
                      icon={<FolderIcon />}
                      label="カタログ"
                      value="/catalogs"
                    />
                    <BottomNavigationAction icon={<AccountCircleIcon />} label="My" value="/my" />
                    <BottomNavigationAction icon={<SearchIcon />} label="検索" value="/search" />
                  </BottomNavigation>
                </Grid>
              </Hidden>
              <Hidden only={['md', 'lg', 'xl']}>
                <Grid container={true}>
                  {router.pathname !== '/signin' && (
                    <React.Suspense fallback={<div />}>
                      <DynamicSignIn />
                    </React.Suspense>
                  )}
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    className={classes.toolbarRight}
                    onClick={() => Router.push('/search')}
                    size="large"
                  >
                    <SearchIcon />
                  </IconButton>
                </Grid>
              </Hidden>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <main className={classes.content}>{props.children}</main>
      <Drawer open={isOpen} onClose={handleDrawerClose}>
        <div className={classes.drawerHeader}>
          <Toolbar>
            <div className={classes.toolbarContainer}>
              <Typography variant="body2" color="inherit" className={classes.caption}>
                化学製品、関連のニュースをいち早く提供します
              </Typography>
              <AnimationSpring>
                <PromotionSignUpForAnonymousUser />
              </AnimationSpring>
            </div>
          </Toolbar>
        </div>
        <Divider />
        <List>
          <ListItem button={true}>
            <Link href={'/'} as={'/'}>
              <ListItemText primary="ホーム" />
            </Link>
          </ListItem>
          <LoginUserMenu />
          <React.Suspense fallback={<div />}>
            <DynamicLogOut onClose={handleDrawerClose} />
          </React.Suspense>
        </List>
      </Drawer>
    </Root>
  )
}

export default AppDrawer
