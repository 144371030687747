import React from 'react'
import { styled } from '@mui/material/styles'
import Router, { useRouter } from 'next/router'
import { BottomNavigation, BottomNavigationAction, Hidden } from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode'
import FolderIcon from '@mui/icons-material/Folder'

const PREFIX = 'Navigation'

const classes = {
  root: `${PREFIX}-root`,
  margin: `${PREFIX}-margin`,
}

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    backgroundColor: '#fafafa',
    borderBottom: '1px solid #9e9e9e',
    borderTop: '1px solid #9e9e9e',
    bottom: 0,
    flexGrow: 1,
    position: 'fixed',
    width: '100vw',
  },

  [`& .${classes.margin}`]: {
    marginTop: theme.spacing(8),
  },
}))

function Navigation() {
  const router = useRouter()

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    Router.push(newValue)
  }

  return (
    <Hidden mdUp={true}>
      <Root>
        <div className={classes.margin} />
        <BottomNavigation
          value={router.pathname}
          onChange={handleChange}
          showLabels={true}
          className={classes.root}
        >
          <BottomNavigationAction icon={<ChromeReaderModeIcon />} label="ニュース" value="/" />
          <BottomNavigationAction icon={<FolderIcon />} label="カタログ" value="/catalogs" />
          <BottomNavigationAction icon={<AccountCircleIcon />} label="My" value="/my" />
        </BottomNavigation>
      </Root>
    </Hidden>
  )
}

export default Navigation
