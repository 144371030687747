import React from 'react'
import { useSpring, animated } from 'react-spring/web.cjs'

export const AnimationSpring: React.FC<{
  children: React.ReactElement
}> = (props) => {
  const springProps = useSpring({ to: { opacity: 1 }, from: { opacity: 0 } })

  return <animated.div style={springProps}>{props.children}</animated.div>
}
